import React from 'react';
import PropTypes from 'prop-types';
import MultivendorCommerceItem from './MultivendorCommerceItem';
import '../../assets/css/BranchOfficesList.scss';

const MultivendorList = ({ directory, commerces }) => {
  const handleBranchOffice = (idpath) => {
    window.location.assign(`${window.location.origin}/${idpath}`);
  };
  return (
    <div style={{ textAlign: 'center', marginBottom: '20px' }}>
      <div style={{ maxWidth: '200px', margin: '0 auto' }}>
        <img
          src={directory.logotipo}
          alt={`Logo de ${directory.nombre}`}
          style={{ maxWidth: '100%', height: 'auto' }}
        />
      </div>
      <h3 className="title_multi">¿Qué quieres comer hoy?</h3>
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px', justifyContent: 'center' }}>
        {commerces &&
          commerces.map((commerce) => (
            <MultivendorCommerceItem
              onClick={() => handleBranchOffice(commerce.id_path)}
              key={commerce.id_path}
              commerce={commerce}
            />
          ))}
      </div>
    </div>
  );
};

export default MultivendorList;

MultivendorList.propTypes = {
  directory: PropTypes.shape({
    nombre: PropTypes.string.isRequired,
    logotipo: PropTypes.string.isRequired,
  }).isRequired,
  commerces: PropTypes.arrayOf(
    PropTypes.shape({
      id_path: PropTypes.string.isRequired,
      cover: PropTypes.string.isRequired,
      logotipo: PropTypes.string.isRequired,
    }),
  ).isRequired,
};
