import React, { useEffect, useState, useRef } from 'react';
import ApiServices from 'ApiServices/ApiServices';
import 'Styles/BranchOfficesList.scss';
import BranchOfficeItem from './BranchOfficeItem';
import PropTypes from 'prop-types';
import logo from 'Images/logo_with_slogan.svg';
import Page404 from 'Pages/Page404';
import logoPlick from 'Images/logo.svg';

function BranchOfficesList({ pathName }) {
  const [branchOfficesData, setBranchOfficesData] = useState({ comercios: [], directorio: {} });
  const [isLoading, setLoading] = useState(true);
  const [isNotFount, setNotFound] = useState(false);
  const loadingPageRef = useRef(null);

  const handleBranchOffice = (idpath) => {
    window.location.assign(`${window.location.origin}/${idpath}`);
  };

  useEffect(function () {
    (async function () {
      try {
        const data = await ApiServices.getBranchOfficesData(pathName);
        if (!data || !data.directorio) setNotFound(true);
        setBranchOfficesData(data);
        loadingPageRef.current.style.opacity = 0;
        loadingPageRef.current.addEventListener(
          'transitionend',
          () => {
            setLoading(false);
          },
          { once: true },
        );
      } catch (error) {
        setNotFound(true);
        window.console.error(error);
        if (loadingPageRef.current) {
          loadingPageRef.current.style.opacity = 0;
          loadingPageRef.current.addEventListener(
            'transitionend',
            () => {
              setLoading(false);
            },
            { once: true },
          );
        }
      }
    })();
  }, []);

  return (
    <>
      {isLoading && (
        <div ref={loadingPageRef} className="loading_page">
          <img src={logo} alt="Plick Logo" />
        </div>
      )}
      {isNotFount ? (
        <Page404 />
      ) : (
        <div className="branch_offices_screen">
          <div className="desktop_header">
            <div className="desktop_header--logo">
              <img src={logoPlick} alt="logo plick" height="25px" />
              <span>
                Pedidos a un <mark>click.</mark>
              </span>
            </div>
          </div>

          <div className="branch_offices_header-list">
            <div className="branch_offices_logo">
              <img src={branchOfficesData.directorio.logotipo} alt="logo" />
            </div>
            <p className="branch_offices_text">Escoge tu sucursal</p>
          </div>
          <div className="branch_offices_list">
            {branchOfficesData.comercios.map((item) => (
              <BranchOfficeItem
                key={item.id_path}
                nombre={item.nombre}
                abierto={item.abierto}
                horarios={item.horarios_dia}
                tags={item.tags}
                tiempomin={item.tiempo_estimado_servicio_domicilio_min}
                tiempomax={item.tiempo_estimado_servicio_domicilio_max}
                direccion={item.direccion}
                cover={item.cover}
                onClick={() => handleBranchOffice(item.id_path)}
              />
            ))}
          </div>

          <div className="bottom-bar">
            <div className="bottom-bar-footer">
              <img src={logoPlick} alt="logo footer" />
              <span>Pedidos a un click</span>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default BranchOfficesList;

BranchOfficesList.propTypes = {
  pathName: PropTypes.string.isRequired,
};
