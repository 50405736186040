/* eslint-disable complexity */
/* global fbq */
/* global google */
import React, { useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import BusinessLocation from 'Elements/BusinessLocation';
import StringInput from 'Elements/StringInput';
import Comments from 'Elements/Comments';
import CartController from 'Controllers/CartController';
import Page from 'Elements/Page';
import BarButton from 'Elements/BarButton';
import { Icon } from '@iconify/react';
import Select from 'Elements/Select';
import EventsManager from 'Controllers/EventsManager';
import 'Styles/DeliveryPage.scss';
import Card from 'Elements/Card';
import Delivery from 'Components/Delivery';
import RoundedRectangleButton from 'Elements/RoundedRectangleButton';
import { useGoogleMapsContext } from '../context/GoogleMapsContext';
import { formatCurrency } from '../functions/money';
import { appCache } from '../utils/cache';
import { ALLOWED_PAYMENT_METHODS_MAP, ALL_ALLOWED_PAYMENT_METHODS, DELIVERY_TYPES_MAP } from '../utils/constants';
import SelectCountries from '../components/elements/SelectCountries';
import { countries } from '../utils/countries';
import { getFlagByCountryCode } from '../utils/getFlagCountryCode';
import ApiServices from 'ApiServices/ApiServices';

const ADDRESS_FIELDS_MAP = {
  zipCode: {
    key: 'zipCode',
    label: 'Código postal',
  },
  city: {
    key: 'city',
    label: 'Ciudad',
  },
  state: {
    key: 'state',
    label: 'Estado',
  },
  country: {
    key: 'country',
    label: 'País',
  },
  street: {
    key: 'street',
    label: 'Calle',
  },
  neighborhood: {
    key: 'neighborhood',
    label: 'Colonia',
  },
  exteriorNumber: {
    key: 'exteriorNumber',
    label: 'Número',
  },
};

const removeLocationDataFromCache = () => {
  localStorage.removeItem('PlickPersonalData-mapsUrl');
  localStorage.removeItem('PlickPersonalData-location-lat');
  localStorage.removeItem('PlickPersonalData-location-lng');
  localStorage.removeItem('PlickPersonalData-full-address');
};

const { por_kilometro, estandar, free, por_colonia: custom, delivery_providers, percent } = DELIVERY_TYPES_MAP;

function PersonalDataPage({ data }) {
  const isLoaded = useGoogleMapsContext();
  const deliveryConfig = data?.config_form_entrega || {
    calle: { required: true, show: true },
    colonia: { required: true, show: true },
    no_ext: { required: true, show: true },
    no_int: { required: false, show: true },
    ubicacion: {
      required: data.deliveryType === por_kilometro || data.deliveryType === delivery_providers,
      show: true,
    },
    referencias: {
      required: data.deliveryType !== delivery_providers,
      show: data.deliveryType !== delivery_providers,
      label: '',
    },
  };
  const [personalData, setPersonalData] = useState({
    name: appCache.name,
    address: appCache.address,
    fullAddress: appCache.fullAddress,
    tableNumber: appCache.tableNumber,
    phoneNumber: appCache.phoneNumber,
    countryCode: appCache.countryCode,
    location: appCache.location,
    mapsUrl: appCache.mapsUrl,
  });
  const [errorState, setErrorState] = useState(false);
  const [deliveryAreaIdx, setDeliveryAreaIdx] = useState(appCache.deliveryAreaIdx);
  const [isOutOfRange, setIsOutOfRange] = useState(false);
  const [isLocating, setIsLocating] = useState(false);
  const [computeDistance, setComputeDistance] = useState(false);
  const [deliveryCost, setDeliveryCost] = useState(data.deliveryPrice || 0);
  const [isUnavailableRoute, setIsUnavailableRoute] = useState(false);
  const [deliveryProviderCode, setDeliveryProviderCode] = useState(null);
  const [isUnavailableCost, setIsUnavailableCost] = useState(false);
  const [allowedPaymentMethods, setAllowedPaymentMethods] = useState([]);
  const [address, setAddress] = useState({
    zipCode: null,
    city: null,
    state: null,
    country: null,
    latitude: null,
    longitude: null,
    fullAddress: null,
    street: null,
    neighborhood: null,
    interiorNumber: null,
    exteriorNumber: null,
  });
  const [missingAddressFields, setMissingAddressFields] = useState([]);

  const getAddressComponents = async ({ lat, lng, fullAddress }) => {
    const geocoder = new google.maps.Geocoder();
    const res = await geocoder.geocode({ location: { lat, lng } });

    if (!res.results?.[0]) {
      const eventsManager = EventsManager.giveEventsManager();
      if (!eventsManager) return;
      eventsManager.publish('displayNotification', {
        type: 'error',
        message: 'No se encontraron resultados, intenta de nuevo',
      });

      return;
    }

    const result = {
      zipCode: res.results[0].address_components.find((comp) => comp.types.includes('postal_code'))?.long_name,
      city: res.results[0].address_components.find((comp) => comp.types.includes('locality'))?.long_name,
      state: res.results[0].address_components.find((comp) => comp.types.includes('administrative_area_level_1'))
        ?.long_name,
      country: res.results[0].address_components.find((comp) => comp.types.includes('country'))?.long_name,
      latitude: String(lat),
      longitude: String(lng),
      fullAddress: fullAddress,
      street:
        res.results[0].address_components.find((comp) => comp.types.includes('route'))?.long_name ||
        res.results[0].address_components.find((comp) => comp.types.includes('sublocality'))?.long_name,
      neighborhood: res.results[0].address_components.find((comp) => comp.types.includes('sublocality'))?.long_name,
      interiorNumber: personalData?.address?.intNumber || 'S/N',
      exteriorNumber: res.results[0].address_components.find((comp) => comp.types.includes('street_number'))?.long_name,
    };

    return result;
  };

  useEffect(() => {
    if (data.deliveryType !== appCache.deliveryType) {
      removeLocationDataFromCache();
      setPersonalData((value) => ({ ...value, location: undefined, mapsUrl: undefined, fullAddress: undefined }));
      appCache.deliveryType = data.deliveryType;
    }
  }, []);

  useEffect(() => {
    if (personalData.location?.lat && personalData.location?.lng) {
      setComputeDistance(true);
    }
  }, [personalData.location]);

  const scroller = useRef(null);

  const [deliveryMethod, setDeliveryMethod] = useState(
    data.availableDeliveryMethods.delivery
      ? 'delivery'
      : data.availableDeliveryMethods.takeAway
      ? 'takeAway'
      : data.availableDeliveryMethods.inRestaurant
      ? 'inRestaurant'
      : null,
  );

  const isNameValid = () => personalData.name.length >= 3;
  const isPhoneValid = () => {
    let phoneNumber = personalData.phoneNumber;
    if (!personalData.countryCode || !phoneNumber) {
      return false;
    }

    const [country] = countries.filter((item) => item.countryCode === parseInt(personalData.countryCode));
    const countriesToMatchPickerZeroValidation = ['MX', 'EC', 'AR', 'DO', 'CO', 'US'];

    if (countriesToMatchPickerZeroValidation.includes(country.code)) {
      phoneNumber = phoneNumber.replace(/^0+/, '');
    }

    return country.phoneLength === phoneNumber.length;
  };
  const isRangeValid = () => {
    if (deliveryMethod !== 'delivery' || data.deliveryType !== por_kilometro) {
      return true;
    }

    return !isOutOfRange;
  };
  const isStreetValid = () =>
    deliveryMethod !== 'delivery' ||
    data.deliveryType === por_kilometro ||
    data.deliveryType === delivery_providers ||
    personalData.address.street.length >= 1;
  const isFullAddressValid = () => {
    if (
      deliveryMethod !== 'delivery' ||
      (data.deliveryType !== por_kilometro && data.deliveryType !== delivery_providers)
    ) {
      return true;
    }

    return personalData.fullAddress.length >= 1 && personalData.location?.lat;
  };
  const isOutNumberValid = () =>
    deliveryMethod !== 'delivery' ||
    data.deliveryType === por_kilometro ||
    data.deliveryType === delivery_providers ||
    personalData.address.outNumber.length > 0;
  const isIntNumberValid = () =>
    deliveryMethod !== 'delivery' ||
    data.deliveryType === por_kilometro ||
    data.deliveryType === delivery_providers ||
    personalData.address.intNumber.length > 0;
  const isRefValid = () => deliveryMethod !== 'delivery' || personalData.address.ref.length >= 5;
  const isZoneValid = () =>
    deliveryMethod !== 'delivery' ||
    data.deliveryType === custom ||
    data.deliveryType === por_kilometro ||
    data.deliveryType === delivery_providers ||
    (personalData.address.zone && personalData.address.zone.length >= 3);
  const isDeliveryAreaValid = () =>
    deliveryMethod !== 'delivery' ||
    data.deliveryType !== custom ||
    (data.deliveryAreas && deliveryAreaIdx >= 0 && deliveryAreaIdx < data.deliveryAreas.length);
  const isTableValid = () => deliveryMethod !== 'inRestaurant' || personalData.tableNumber;
  const isLocationValid = () =>
    deliveryMethod !== 'delivery' || (personalData.location?.lat && personalData.location?.lng);
  const isCostRangeAndRouteValid = () => {
    if (
      deliveryMethod !== 'delivery' ||
      (data.deliveryType !== por_kilometro && data.deliveryType !== delivery_providers)
    ) {
      return true;
    }

    return !isOutOfRange && !isUnavailableRoute && !isUnavailableCost;
  };

  useState(() => {
    if (fbq) fbq('trackCustom', 'DeliveryMethod', { value: deliveryMethod });
  }, [deliveryMethod]);

  const validator = {
    isLocationValid: () =>
      !(
        (deliveryConfig.ubicacion.required ||
          data.deliveryType === por_kilometro ||
          data.deliveryType === delivery_providers) &&
        !isLocationValid()
      ),
    isZoneValid: () => !(deliveryConfig.colonia.required && !isZoneValid()),
    isDeliveryAreaValid: () =>
      !((deliveryConfig.colonia.required || data.deliveryType === custom) && !isDeliveryAreaValid()),
    isStreetValid: () => !(deliveryConfig.calle.required && !isStreetValid()),
    isOutNumberValid: () => !(deliveryConfig.no_ext.required && !isOutNumberValid()),
    isIntNumberValid: () => !(deliveryConfig.no_int.required && !isIntNumberValid()),
    isRefValid: () =>
      !(
        (deliveryConfig.referencias.required ||
          (deliveryMethod === 'delivery' && data.deliveryType === delivery_providers)) &&
        !isRefValid()
      ),
  };

  const handleFinishClick = useCallback(() => {
    let cartTotal = 0;
    const cartController = CartController.giveCartController();
    if (cartController) cartTotal = cartController.getTotal();
    if (
      !isNameValid() ||
      !isPhoneValid() ||
      !validator.isLocationValid() ||
      !validator.isZoneValid() ||
      !validator.isDeliveryAreaValid() ||
      !validator.isStreetValid() ||
      !validator.isOutNumberValid() ||
      !validator.isIntNumberValid() ||
      !validator.isRefValid() ||
      !isFullAddressValid() ||
      !isTableValid() ||
      !isRangeValid()
    ) {
      if (errorState) {
        let errorBoxes = scroller.current.querySelectorAll('[box_error="true"]');
        scroller.current.scroll({
          top: errorBoxes[0]?.offsetTop - 20,
          behavior: 'smooth',
        });
        for (let ii = 0; ii < errorBoxes.length; ii++) {
          const element = errorBoxes[ii];
          element.classList.add('animateBoxError');
        }
      }
      setErrorState(true);
      return;
    }
    if (
      deliveryMethod === 'delivery' &&
      data.deliveryType === custom &&
      data.deliveryAreas &&
      data.deliveryAreas[deliveryAreaIdx]
    )
      personalData.address.zone = data.deliveryAreas[deliveryAreaIdx].name;
    let eventsManager = EventsManager.giveEventsManager();
    if (eventsManager)
      eventsManager.publish('onFinishDeliveryClick', {
        personalData: {
          ...personalData,
          address: {
            intNumber: deliveryConfig.no_int.show ? personalData.address.intNumber : '',
            outNumber: deliveryConfig.no_ext.show ? personalData.address.outNumber : '',
            ref:
              deliveryConfig.referencias.show ||
              (deliveryMethod === 'delivery' && data.deliveryType === delivery_providers)
                ? personalData.address.ref
                : '',
            street: deliveryConfig.calle.show ? personalData.address.street : '',
            zone: deliveryConfig.colonia.show ? personalData.address.zone : '',
          },
          fullAddress:
            deliveryConfig.ubicacion.show ||
            data.deliveryType === por_kilometro ||
            data.deliveryType === delivery_providers
              ? personalData.fullAddress
              : '',
          location:
            deliveryConfig.ubicacion.show ||
            data.deliveryType === por_kilometro ||
            data.deliveryType === delivery_providers
              ? personalData.location
              : undefined,
          mapsUrl:
            deliveryConfig.ubicacion.show || data.deliveryType === por_kilometro ? personalData.mapsUrl : undefined,
        },
        freeDelivery: data.freeDelivery && cartTotal >= data.freeDeliveryAmount,
        freeDeliveryAmount: data.freeDeliveryAmount,
        deliveryMethod: deliveryMethod,
        allowedPaymentMethods: (deliveryMethod === 'delivery' && data.deliveryType === delivery_providers
          ? allowedPaymentMethods
          : ALL_ALLOWED_PAYMENT_METHODS
        ).filter((method) => {
          if (method === ALLOWED_PAYMENT_METHODS_MAP.payment_provider.key && !data?.paymentProviderKey) {
            return null;
          }

          return method;
        }),
        providerCode:
          deliveryMethod === 'delivery' && data.deliveryType === delivery_providers ? deliveryProviderCode : null,
        deliveryType: data.deliveryType,
        deliveryPrice:
          data.freeDelivery && cartTotal >= data.freeDeliveryAmount
            ? 0
            : deliveryMethod === 'delivery' &&
              data.deliveryType === custom &&
              data.deliveryAreas &&
              data.deliveryAreas[deliveryAreaIdx]
            ? data.deliveryAreas[deliveryAreaIdx].price
            : deliveryCost,
        deliveryArea:
          deliveryMethod === 'delivery' &&
          data.deliveryType === custom &&
          data.deliveryAreas &&
          data.deliveryAreas[deliveryAreaIdx],
        address: address,
      });
  }, [personalData, deliveryAreaIdx, errorState, deliveryMethod, isOutOfRange, deliveryCost, address]);

  useEffect(() => {
    if (errorState) {
      let errorBox = scroller.current.querySelector('[box_error="true"]');
      scroller.current.scroll({
        top: errorBox?.offsetTop - 20,
        behavior: 'smooth',
      });
    }
  }, [errorState]);

  let total = 0,
    cartTotal = 0,
    cartItems = 0;

  const cartController = CartController.giveCartController();
  if (cartController) {
    cartItems = cartController.getProductsLength();
    cartTotal = cartController.getTotal();
  }

  total += cartTotal;
  if (
    deliveryMethod === 'delivery' &&
    data.deliveryType !== por_kilometro &&
    data.deliveryType !== delivery_providers &&
    data.deliveryType !== percent
  ) {
    if (!data.freeDelivery || data.freeDeliveryAmount > cartTotal) {
      if (data.deliveryType === custom && data.deliveryAreas && data.deliveryAreas.length)
        total +=
          deliveryAreaIdx >= 0 && data.deliveryAreas[deliveryAreaIdx] ? data.deliveryAreas[deliveryAreaIdx].price : 0;
      else total += data.deliveryPrice !== undefined ? data.deliveryPrice : 0;
    }
  } else if (deliveryMethod === 'delivery') {
    total += deliveryCost;
  }

  useEffect(() => {
    if (!computeDistance || deliveryMethod !== 'delivery' || data.deliveryType !== por_kilometro) return;
    if (isLoaded && personalData.fullAddress && personalData?.location?.lat && data.informacion_envio) {
      const directionsService = new window.google.maps.DirectionsService();
      setIsLocating(true);

      const request = {
        origin: {
          lat: data.commerceLocation.latitud,
          lng: data.commerceLocation.longitud,
        },
        destination: personalData.location,
        travelMode: google.maps.TravelMode.DRIVING,
        avoidTolls: true,
      };

      directionsService.route(request, (result, status) => {
        if (status === google.maps.DirectionsStatus.OK && result) {
          setIsUnavailableRoute(false);
          const route = result.routes[0];
          const distanceInMeters = route.legs.reduce((t, l) => t + (l?.distance?.value || 0), 0);
          const distance = distanceInMeters / 1000;
          const { costo_km, min_km, max_km } = data.informacion_envio;
          const km_extras = distance - min_km;
          const fixedKmExtras = km_extras >= 0 ? km_extras : 0;
          const costo = Math.ceil(data.deliveryPrice + fixedKmExtras * costo_km);

          if (data.freeDelivery && cartTotal >= data.freeDeliveryAmount) {
            setDeliveryCost(0);
          } else {
            setDeliveryCost(costo);
          }

          setIsOutOfRange(distance > max_km);
          setIsLocating(false);
          setComputeDistance(false);
        } else if (status === google.maps.DirectionsStatus.ZERO_RESULTS) {
          setIsUnavailableRoute(true);
          const eventsManager = EventsManager.giveEventsManager();
          if (!eventsManager) return;
          eventsManager.publish('displayNotification', {
            type: 'error',
            message: 'No hay rutas disponibles',
          });
          setIsLocating(false);
        } else {
          setIsUnavailableRoute(true);
          const eventsManager = EventsManager.giveEventsManager();
          if (!eventsManager) return;
          eventsManager.publish('displayNotification', {
            type: 'error',
            message: 'Hubo un error al calcular el costo de envío',
          });
          setIsLocating(false);
        }
      });
    }
  }, [isLoaded, personalData.fullAddress, personalData.location, computeDistance, deliveryMethod]);

  useEffect(() => {
    if (deliveryMethod !== 'delivery' || data.deliveryType !== percent) return;
    if (!data.deliveryPercentageQty) {
      setIsLocating(true);

      const eventsManager = EventsManager.giveEventsManager();
      if (!eventsManager) return;
      eventsManager.publish('displayNotification', {
        type: 'error',
        message: 'Comunicate con el comercio para que configure el porcentaje de envío',
      });

      return;
    }

    const controller = CartController.giveCartController();
    const subtotal = controller.getTotal();
    if (data.freeDelivery && cartTotal >= data.freeDeliveryAmount) {
      setDeliveryCost(0);
    } else {
      setDeliveryCost((subtotal * data.deliveryPercentageQty) / 100);
    }
  }, [data.deliveryPercentageQty]);

  const handleReCalculateDeliveryProviderCost = () => {
    if (deliveryMethod !== 'delivery' || data.deliveryType !== delivery_providers) return;

    setIsLocating(true);

    const missingFields = Object.keys(address).filter((key) => !address[key]);
    setMissingAddressFields(missingFields);
    if (missingFields.length > 0) {
      setIsLocating(false);
      setIsUnavailableCost(true);

      const eventsManager = EventsManager.giveEventsManager();
      if (!eventsManager) return;
      eventsManager.publish('displayNotification', {
        type: 'error',
        message: 'Completa los campos faltantes',
      });
      return;
    }

    const controller = CartController.giveCartController();
    const subtotal = controller.getTotal();

    ApiServices.getThirdPartyDeliveryCost({
      commerceId: data.commerceId,
      address: address,
      subtotal: subtotal,
    }).then((response) => {
      if (response?.error) {
        setIsUnavailableCost(true);
        setIsLocating(false);

        const eventsManager = EventsManager.giveEventsManager();
        if (!eventsManager) return;
        eventsManager.publish('displayNotification', {
          type: 'error',
          message: response.error,
        });

        return;
      }

      setDeliveryCost(response.deliveryFeeTotal);
      setAllowedPaymentMethods(response?.allowedPaymentMethods || []);
      setDeliveryProviderCode(response?.providerCode);
      setIsUnavailableCost(false);
      setIsLocating(false);
    });
  };

  useEffect(() => {
    const getCost = async () => {
      if (!computeDistance || deliveryMethod !== 'delivery' || data.deliveryType !== delivery_providers) return;
      if (isLoaded && personalData.fullAddress && personalData?.location?.lat) {
        setIsLocating(true);
        const controller = CartController.giveCartController();
        const subtotal = controller.getTotal();

        const newAddress = await getAddressComponents({
          lat: personalData.location.lat,
          lng: personalData.location.lng,
          fullAddress: personalData.fullAddress,
        });

        setAddress(newAddress);

        const missingFields = Object.keys(newAddress).filter((key) => !newAddress[key]);
        setMissingAddressFields(missingFields);
        if (missingFields.length > 0) {
          setIsLocating(false);
          setIsUnavailableCost(true);
          return;
        }

        ApiServices.getThirdPartyDeliveryCost({
          commerceId: data.commerceId,
          address: newAddress,
          subtotal: subtotal,
        }).then((response) => {
          if (response?.error) {
            setIsUnavailableCost(true);
            setIsLocating(false);

            const eventsManager = EventsManager.giveEventsManager();
            if (!eventsManager) return;
            eventsManager.publish('displayNotification', {
              type: 'error',
              message: response.error,
            });

            return;
          }

          setDeliveryCost(response.deliveryFeeTotal);
          setAllowedPaymentMethods(response?.allowedPaymentMethods || []);
          setDeliveryProviderCode(response?.providerCode);
          setIsUnavailableCost(false);
          setIsLocating(false);
        });
      }
    };

    getCost();
  }, [isLoaded, personalData.fullAddress, personalData.location, computeDistance, deliveryMethod]);

  useEffect(() => {
    if (
      isLoaded &&
      navigator?.geolocation?.getCurrentPosition &&
      (data.deliveryType === por_kilometro || data.deliveryType === delivery_providers) &&
      (!personalData.fullAddress || !personalData.location?.lat)
    ) {
      setIsLocating(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const reverseGeocoder = new window.google.maps.Geocoder();
          reverseGeocoder.geocode(
            { location: { lat: position.coords.latitude, lng: position.coords.longitude } },
            (results, status) => {
              if (status === 'OK') {
                if (results?.[0]) {
                  appCache.location = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                  };
                  appCache.fullAddress = results[0].formatted_address;
                  appCache.mapsUrl = `https://www.google.com/maps/search/?api=1&query=${position.coords.latitude},${position.coords.longitude}`;
                  setPersonalData({
                    ...personalData,
                    location: {
                      lat: position.coords.latitude,
                      lng: position.coords.longitude,
                    },
                    fullAddress: results[0].formatted_address,
                    mapsUrl: `https://www.google.com/maps/search/?api=1&query=${position.coords.latitude},${position.coords.longitude}`,
                  });
                  setIsLocating(false);
                }
              }
            },
          );
        },
        () => {
          setIsLocating(false);
        },
      );
    }
  }, [isLoaded, personalData.fullAddress, personalData.location]);

  const nameErrorState = errorState && !isNameValid();
  const phoneNumberErrorState = errorState && !isPhoneValid();
  const tableNumberErrorState = errorState && !isTableValid();
  const deliveryAreaErrorState = errorState && !validator.isDeliveryAreaValid();
  const locationErrorState = errorState && !validator.isLocationValid();
  const streetErrorState = errorState && !validator.isStreetValid();
  const outNumberErrorState = errorState && !validator.isOutNumberValid();
  const intNumberErrorState = errorState && !validator.isIntNumberValid();
  const refErrorState = errorState && !validator.isRefValid();
  const zoneErrorState = errorState && !validator.isZoneValid();
  const rangeErrorState = errorState && !isRangeValid();

  const hasGenericLocation =
    deliveryConfig.ubicacion.show &&
    personalData.fullAddress &&
    data.deliveryType !== por_kilometro &&
    data.deliveryType !== delivery_providers;

  const hasLocationKmOrProvidersLocation =
    (data.deliveryType === por_kilometro || data.deliveryType === delivery_providers) &&
    personalData.location &&
    personalData.fullAddress;

  return (
    <Page id="Peronal_Data_Page">
      <div ref={scroller} className="delivery_page">
        <p className="delivery_page-title">Completa los datos</p>
        <Card title="Datos Personales" error={nameErrorState || phoneNumberErrorState}>
          <StringInput
            title="Nombre:"
            error={nameErrorState}
            placeHolder="Pedro Navajas"
            type="text"
            name="name"
            value={personalData.name}
            onValueChange={(value) => {
              appCache.name = value;
              setPersonalData({ ...personalData, name: value });
            }}
          />
          <div className="container-countries">
            <div className="container-countries__select">
              <label className="container-countries__select-label">Código:</label>
              <SelectCountries
                defaultValue={personalData.countryCode}
                flag={getFlagByCountryCode(parseInt(personalData.countryCode))}
                isSearchable={false}
                onChange={(e) => {
                  appCache.countryCode = String(e.value);
                  setPersonalData({ ...personalData, countryCode: e.value });
                }}
              />
            </div>
            <div className="container-countries__phone">
              <StringInput
                title="Número de celular:"
                error={phoneNumberErrorState}
                placeHolder="0000000000"
                type="tel"
                name="phone"
                value={personalData.phoneNumber}
                onValueChange={(value) => {
                  const fixedValue = value.replace(/[^0-9]/g, '');
                  appCache.phoneNumber = fixedValue;
                  setPersonalData({ ...personalData, phoneNumber: fixedValue });
                }}
              />
            </div>
          </div>
        </Card>

        <Delivery
          total={cartTotal}
          options={data.availableDeliveryMethods}
          deliveryPrice={deliveryCost}
          deliveryMethod={deliveryMethod}
          onValueChange={setDeliveryMethod}
          commerceCountryCode={data.commerceCountryCode}
          commercePhone={data.commercePhone}
          commerceName={data.commerceName}
          compra_minima_envio_domicilio={data.compra_minima_envio_domicilio}
        />

        {deliveryMethod === 'delivery' ? (
          <>
            <Card
              title="Dirección de entrega:"
              subTitle={
                data.deliveryType !== custom
                  ? `Costo de envío: ${
                      data.deliveryType === free || (data.freeDelivery && cartTotal >= data.freeDeliveryAmount)
                        ? 'Gratis'
                        : !deliveryCost && data.deliveryType === estandar
                        ? 'Pendiente'
                        : formatCurrency(deliveryCost)
                    }`
                  : ''
              }
              error={
                rangeErrorState ||
                locationErrorState ||
                streetErrorState ||
                outNumberErrorState ||
                intNumberErrorState ||
                refErrorState ||
                zoneErrorState
              }
            >
              <div className="delivery_page-km_address-container">
                {(data.deliveryType === por_kilometro || data.deliveryType === delivery_providers) &&
                personalData.fullAddress ? (
                  <p className="delivery_page-km_address">{personalData.fullAddress}</p>
                ) : null}
                {data.deliveryType === por_kilometro ||
                data.deliveryType === delivery_providers ||
                deliveryConfig.ubicacion.show ? (
                  <RoundedRectangleButton
                    className={`share-position_button ${hasLocationKmOrProvidersLocation ? 'large_button' : ''} ${
                      locationErrorState ? 'error_button' : ''
                    }`}
                    icon={
                      hasGenericLocation || hasLocationKmOrProvidersLocation
                        ? 'fluent:edit-12-regular'
                        : 'fa6-solid:map-location-dot'
                    }
                    label={
                      hasGenericLocation
                        ? 'Editar ubicación'
                        : hasLocationKmOrProvidersLocation
                        ? ''
                        : 'Compartir mi ubicación'
                    }
                    onClick={() => {
                      let eventsManager = EventsManager.giveEventsManager();
                      if (eventsManager) {
                        eventsManager.publish('onMapDialogRequest', {
                          location: personalData.location,
                          fullAddress: personalData.fullAddress,
                        });
                        let token = eventsManager.subscribe('onPageExit', (result) => {
                          eventsManager.unsubscribe(token);
                          if (result) {
                            if (result.location) appCache.location = result.location;
                            if (result.fullAddress) appCache.fullAddress = result.fullAddress;
                            if (result.location?.lat)
                              appCache.mapsUrl = `https://www.google.com/maps/search/?api=1&query=${result.location.lat}%2C${result.location.lng}`;
                            setPersonalData({
                              ...personalData,
                              address: {
                                ...personalData.address,
                                zone: result.zone,
                                street: result.street,
                                outNumber: result.outNumber,
                              },
                              location: result.location?.lat
                                ? { lat: result.location.lat, lng: result.location.lng }
                                : null,
                              mapsUrl: result.location?.lat
                                ? `https://www.google.com/maps/search/?api=1&query=${result.location.lat}%2C${result.location.lng}`
                                : null,
                              fullAddress: result.fullAddress || personalData.fullAddress,
                            });
                          }
                        });
                      }
                    }}
                  />
                ) : null}
              </div>
              {(data.deliveryType === por_kilometro || data.deliveryType === delivery_providers) &&
                personalData.fullAddress && (
                  <>
                    {isOutOfRange && (
                      <p className="delivery_page-km_address-error">
                        Esta dirección está fuera del alcance de envíos, el alcance máximo son{' '}
                        {data.informacion_envio?.max_km}km
                      </p>
                    )}
                    {isUnavailableRoute && (
                      <p className="delivery_page-km_address-error">
                        No hay rutas disponibles para esta dirección, si tu dirección es dentro de una privada o un
                        residencial intenta seleccionando el acceso principal.
                      </p>
                    )}
                  </>
                )}
              {data.deliveryType === custom && data.deliveryAreas && data.deliveryAreas.length ? (
                <div className="delivery_page-area">
                  <span>El servicio a domicilio es exclusivo para las siguientes zonas o colonias:</span>
                  <Select
                    error={deliveryAreaErrorState}
                    placeHolder="Selecciona tu zona o colonia de envío"
                    label="Buscar zonas o colonias"
                    currentIndex={deliveryAreaIdx}
                    items={data.deliveryAreas.map((deliveryArea) => deliveryArea.name)}
                    onChange={(value) => {
                      appCache.deliveryAreaIdx = value;
                      setDeliveryAreaIdx(value);
                    }}
                  />
                  <div className="delivery_page-envío">
                    <span className="card-subtitle">{`Costo de envío: ${
                      deliveryAreaIdx >= 0 && data.deliveryAreas[deliveryAreaIdx]
                        ? data.freeDelivery && cartTotal >= data.freeDeliveryAmount
                          ? 'Gratis'
                          : '$' + data.deliveryAreas[deliveryAreaIdx].price
                        : '-'
                    }`}</span>
                  </div>
                </div>
              ) : data.deliveryType !== por_kilometro &&
                data.deliveryType !== delivery_providers &&
                deliveryConfig.colonia.show ? (
                <StringInput
                  error={zoneErrorState}
                  title="Zona, colonia o super manzana:"
                  placeHolder="Zona, colonia o super manzana"
                  type="text"
                  allowNumbers={true}
                  name="zone"
                  value={personalData.address.zone}
                  onValueChange={(value) => {
                    appCache.address.zone = value;
                    setPersonalData({ ...personalData, address: { ...personalData.address, zone: value } });
                  }}
                />
              ) : null}

              {data.deliveryType !== por_kilometro && data.deliveryType !== delivery_providers ? (
                <>
                  {deliveryConfig.calle.show && (
                    <StringInput
                      error={streetErrorState}
                      title="Calle o mz:"
                      placeHolder="Nombre de la calle"
                      type="text"
                      allowNumbers={true}
                      name="street"
                      value={personalData.address.street}
                      onValueChange={(value) => {
                        appCache.address.street = value;
                        setPersonalData({
                          ...personalData,
                          address: { ...personalData.address, street: value },
                        });
                      }}
                    />
                  )}

                  <div style={{ display: 'flex', width: '100%' }}>
                    {deliveryConfig.no_ext.show && (
                      <StringInput
                        error={outNumberErrorState}
                        title="Número exterior:"
                        placeHolder="000"
                        allowNumbers
                        type="text"
                        value={personalData.address.outNumber}
                        onValueChange={(value) => {
                          appCache.address.outNumber = value;
                          setPersonalData({ ...personalData, address: { ...personalData.address, outNumber: value } });
                        }}
                      />
                    )}
                    {deliveryConfig.no_int.show && deliveryConfig.no_ext.show ? (
                      <div style={{ width: '20px' }} />
                    ) : null}
                    {deliveryConfig.no_int.show && (
                      <StringInput
                        error={intNumberErrorState}
                        title="Número interior:"
                        placeHolder="000"
                        allowNumbers
                        type="text"
                        value={personalData.address.intNumber}
                        onValueChange={(value) => {
                          appCache.address.intNumber = value;
                          setPersonalData({ ...personalData, address: { ...personalData.address, intNumber: value } });
                        }}
                      />
                    )}
                  </div>
                </>
              ) : null}
              {missingAddressFields.length > 0 ? (
                <div style={{ width: '100%' }}>
                  <p
                    style={{
                      fontWeight: 700,
                      textAlign: 'center',
                      marginTop: '5px',
                      marginBottom: '10px',
                      color: 'red',
                    }}
                  >
                    Completa los siguientes campos
                  </p>
                  <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: '15px', rowGap: '10px' }}>
                    {missingAddressFields.map((field) => (
                      <StringInput
                        key={field}
                        title={ADDRESS_FIELDS_MAP[field]?.label}
                        placeHolder=""
                        type="text"
                        allowNumbers={true}
                        name={field}
                        value={address[field] || ''}
                        onValueChange={(value) => {
                          setAddress({ ...address, [field]: value });
                        }}
                      />
                    ))}
                  </div>
                  <div style={{ width: '100%', marginTop: '30px' }}>
                    <RoundedRectangleButton
                      className="submit_button button_full"
                      label="Validar mi dirección"
                      onClick={() => {
                        handleReCalculateDeliveryProviderCost();
                      }}
                    />
                  </div>
                </div>
              ) : null}

              {!isLocating &&
              missingAddressFields.length === 0 &&
              (deliveryConfig.referencias.show ||
                (deliveryMethod === 'delivery' && data.deliveryType === delivery_providers)) ? (
                <Comments
                  error={refErrorState}
                  title={`${deliveryConfig.referencias.label || 'Referencias'}:`}
                  value={personalData.address.ref}
                  placeHolder="Ej: Entre calle 1 y calle 2, una casa amarilla"
                  onValueChange={(value) => {
                    appCache.address.ref = value;
                    setPersonalData({ ...personalData, address: { ...personalData.address, ref: value } });
                  }}
                />
              ) : null}
            </Card>
          </>
        ) : null}

        {deliveryMethod === 'takeAway' ? (
          <Card title="Dirección del comercio:">
            <BusinessLocation
              location={data.commerceAddress}
              mapsUrl={data.commerceMapsUrl}
              commerceLocation={data.commerceLocation}
            />
          </Card>
        ) : null}

        {deliveryMethod === 'inRestaurant' ? (
          <Card title="Número de mesa:" error={tableNumberErrorState}>
            <StringInput
              placeHolder="Número de mesa"
              type="number"
              value={personalData.tableNumber}
              onValueChange={(value) => {
                appCache.tableNumber = value;
                setPersonalData({ ...personalData, tableNumber: value });
              }}
            />
          </Card>
        ) : null}
      </div>
      <BarButton
        onClick={
          isLocating ||
          !isCostRangeAndRouteValid() ||
          !deliveryMethod ||
          (deliveryMethod === 'delivery' &&
            data.compra_minima_envio_domicilio &&
            cartTotal < data.compra_minima_envio_domicilio)
            ? () => null
            : handleFinishClick
        }
        disabled={
          isLocating ||
          !isCostRangeAndRouteValid() ||
          !deliveryMethod ||
          (deliveryMethod === 'delivery' &&
            data.compra_minima_envio_domicilio &&
            cartTotal < data.compra_minima_envio_domicilio)
        }
      >
        <div className="home_page-bar_button">
          <div className="home_page-bar_button--icon">
            <div className="home_page-bar_button--items">{cartItems}</div>
            <Icon icon="uil:shopping-cart" />
          </div>
          <span className="home_page-bar_button--label">
            <div>Total:</div>
            <div>{formatCurrency(total)}</div>
          </span>
          <div className="home_page-bar_button--bar" />
          <span className="home_page-bar_button--next">{isLocating ? 'Cargando...' : 'Continuar'}</span>
          <Icon icon={isLocating ? 'eos-icons:loading' : 'uil:angle-right'} />
        </div>
      </BarButton>
    </Page>
  );
}

export default PersonalDataPage;

PersonalDataPage.propTypes = {
  data: PropTypes.exact({
    availableDeliveryMethods: PropTypes.exact({
      delivery: PropTypes.bool,
      takeAway: PropTypes.bool,
      inRestaurant: PropTypes.bool,
      freeDelivery: PropTypes.bool,
      freeDeliveryAmount: PropTypes.number,
    }).isRequired,
    deliveryPrice: PropTypes.number,
    commerceAddress: PropTypes.string.isRequired,
    commerceMapsUrl: PropTypes.string.isRequired,
    commerceId: PropTypes.string.isRequired,
    commercePhone: PropTypes.number.isRequired,
    commerceCountryCode: PropTypes.number.isRequired,
    commerceName: PropTypes.string.isRequired,
    commerceLocation: PropTypes.exact({
      latitud: PropTypes.number,
      longitud: PropTypes.number,
    }),
    config_form_entrega:
      PropTypes.exact({
        calle: PropTypes.exact({
          show: PropTypes.bool,
          required: PropTypes.bool,
        }),
        colonia: PropTypes.exact({
          show: PropTypes.bool,
          required: PropTypes.bool,
        }),
        no_ext: PropTypes.exact({
          show: PropTypes.bool,
          required: PropTypes.bool,
        }),
        no_int: PropTypes.exact({
          show: PropTypes.bool,
          required: PropTypes.bool,
        }),
        referencias: PropTypes.exact({
          show: PropTypes.bool,
          required: PropTypes.bool,
          label: PropTypes.string,
        }),
        ubicacion: PropTypes.exact({
          show: PropTypes.bool,
          required: PropTypes.bool,
        }),
      }) || null,
    deliveryType: PropTypes.oneOf(Object.values(DELIVERY_TYPES_MAP)).isRequired,
    deliveryPercentageQty: PropTypes.number,
    freeDelivery: PropTypes.bool,
    freeDeliveryAmount: PropTypes.number,
    paymentProviderKey: PropTypes.string,
    compra_minima_envio_domicilio: PropTypes.number || null,
    deliveryAreas: PropTypes.arrayOf(
      PropTypes.exact({
        name: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
      }),
    ),
    informacion_envio:
      PropTypes.exact({
        costo_km: PropTypes.number,
        max_km: PropTypes.number,
        min_km: PropTypes.number,
      }) || null,
  }),
};
