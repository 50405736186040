import React from 'react';
import StatusLabel from 'Elements/StatusLabel';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import CoverDefault from '../../assets/images/default-cover.jpg';
import LogoDefault from '../../assets/images/icon256.jpg';
import '../../assets/css/MultivendorCommerceItem.scss';

const MultivendorCommerceItem = ({ commerce }) => {
  const openHours = commerce.horarios_dia && commerce.horarios_dia[0];

  const handleBranchOffice = (idpath) => {
    window.location.assign(`${window.location.origin}/${idpath}`);
  };

  const handleDirectionsClick = (e) => {
    e.stopPropagation();
    if (!commerce.geolocalizacion) return;
    const { latitud, longitud } = commerce.geolocalizacion;
    window.open(`https://www.google.com/maps/dir/?api=1&destination=${latitud},${longitud}&dirflg=r`, '_blank');
  };

  return (
    <div key={commerce.id_path} className="multivendor_item">
      <div className="div_cover-item">
        <img
          src={commerce?.cover ? commerce.cover : CoverDefault}
          alt={`Cover of ${commerce.id_path}`}
          className="cover_item"
        />
      </div>
      <div className="div_name-status">
        <div className="branch_office_item_header">
          <img className="logo_item" src={commerce.logotipo ? commerce.logotipo : LogoDefault} width="45" />
        </div>
        <div className="div_data-info">
          <div className="div_name-commerce">
            <p className="">{commerce.nombre}</p>
          </div>
          <div className="div_horarios-item">
            <StatusLabel isOpen={commerce.abierto} />
            <p className="branch_office_item_text branch_office_item_schedule">| Horario:</p>
            {openHours && (
              <p className="branch_office_item_hour">{`${openHours.horario_apertura}h a ${openHours.horario_cierre}h`}</p>
            )}
            {!openHours && <p className="branch_office_item_hour">No laboramos</p>}
          </div>
        </div>
      </div>
      {commerce.tags && (
        <div className="branch_office_item_tags">
          {commerce.tags.map((item, idx) => {
            return (
              <div className="branch_office_item_tags_name" key={'BranchOfficeItem-' + idx}>
                {item.nombre}
              </div>
            );
          })}
        </div>
      )}
      {commerce.direccion && (
        <div className="commerce_address">
          <Icon icon="mdi:google-maps" width="38px" height="38px" color="#14202C" />

          {commerce.direccion}
        </div>
      )}
      <div className="branch_office_item_buttons">
        {commerce.geolocalizacion && commerce.geolocalizacion.latitud && commerce.geolocalizacion.longitud && (
          <button className="branch_office_item_menu" onClick={handleDirectionsClick}>
            Cómo llegar
          </button>
        )}
        <button
          className="branch_office_item_menu primary"
          onClick={(e) => {
            e.stopPropagation();
            handleBranchOffice(commerce.id_path);
          }}
        >
          Ver menú
        </button>
      </div>
    </div>
  );
};

export default MultivendorCommerceItem;

MultivendorCommerceItem.propTypes = {
  commerce: PropTypes.shape({
    nombre: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    id_path: PropTypes.string.isRequired,
    cover: PropTypes.string.isRequired,
    abierto: PropTypes.bool.isRequired,
    horarios_dia: PropTypes.string.isRequired,
    direccion: PropTypes.string.isRequired,
    logotipo: PropTypes.string.isRequired,
    tags: PropTypes.arrayOf(PropTypes.exact({ id: PropTypes.string, nombre: PropTypes.string })),
    geolocalizacion: PropTypes.shape({
      latitud: PropTypes.number,
      longitud: PropTypes.number,
    }),
  }),
};
