/* global fbq */
import React, { useState, useEffect, useCallback, memo, useRef } from 'react';
import PropTypes from 'prop-types';
import EventsManager from 'Controllers/EventsManager';
import ListBox from 'Components/ListBox';
import 'Styles/ProductPage.scss';
import ApiServices from 'ApiServices/ApiServices';
import CartController from 'Controllers/CartController';
import LineCheckBox from 'Elements/LineCheckBox';
import Page from 'Elements/Page';
import BarButton from 'Elements/BarButton';
import FlatButton from 'Elements/FlatButton';
import { Icon } from '@iconify/react';
import Card from 'Elements/Card';
import { formatCurrency } from '../functions/money';
import Comments from '../components/elements/Comments';
import { motion, useDomEvent } from 'framer-motion';

function ProductPage({ data }) {
  const name = data.name;
  const description = data.description;
  const image = ApiServices.getImagePath(data.image);
  const isUpdate = Boolean(data.itemId);
  const isDisabled = data.isDisabled;

  const scroller = useRef(null);

  const [itemCount, setItemCount] = useState(isDisabled ? 0 : data.count || 1);
  const [cartOptions, setCartOption] = useState(data.options);
  const [cartSections, setCartSections] = useState(data.sections);
  const [cartSuggestions, setCartSuggestions] = useState(data.suggestions);
  const [directions, setDirections] = useState(data.directions);
  const [errorState, setErrorState] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [isOpenImg, setOpenImg] = useState(false);

  const requiredSections = cartSections.filter((section) => section.isRequired && !section.isDisabled);
  const invalidCart = requiredSections.some(
    (section) => section.content.reduce((acc, item) => acc + item.count, 0) < section.maxValues,
  );
  const isLoading = cartSections.some((section) => typeof section.content[0] === 'string');

  const transition = {
    type: 'spring',
    damping: 25,
    stiffness: 120,
  };

  useDomEvent(useRef(window), 'scroll', () => isOpenImg && setOpenImg(false));

  useEffect(() => {
    if (!data.extras) {
      return;
    }
    if (data.sections && !isUpdate) {
      const sections = [];
      data.sections.forEach((section) => {
        if (section.content.length > 0) {
          const content = section.content.map((extra) => {
            const extraData = data.extras.find((item) => item.id === extra);
            return extraData
              ? {
                  count: 0,
                  id: extraData.id,
                  label: extraData.nombre,
                  price: extraData.precio,
                  isDisabled: extraData.estatus !== 'activo',
                }
              : null;
          });
          const filteredContent = content.filter((item) => item);
          sections.push({ ...section, content: filteredContent });
        }
      });

      setCartSections(sections);
    }
    if (data.suggestions && !isUpdate) {
      const suggestions = [];
      data.suggestions.forEach((suggest) => {
        if (suggest.content.length > 0) {
          const content = suggest.content.map((extra) => {
            const suggestData = data.extras.find((item) => item.id === extra);
            return suggestData
              ? {
                  count: 0,
                  id: suggestData.id,
                  label: suggestData.nombre,
                  price: suggestData.precio,
                  isDisabled: suggestData.estatus !== 'activo',
                }
              : null;
          });
          const filteredContent = content.filter((item) => item);
          suggestions.push({ ...suggest, content: filteredContent });
        }
      });

      setCartSuggestions(suggestions);
    }
  }, [data.extras]);

  useEffect(() => {
    if (errorState) {
      let errorBox = scroller.current.querySelector('[box_error="true"]');
      scroller.current.scroll({
        top: errorBox.offsetTop - 20,
        behavior: 'smooth',
      });
    }
  }, [errorState]);

  const handleCartButton = useCallback(() => {
    if (isLoading) return;
    if (isDisabled) {
      let message = 'Producto no disponible';
      let today = new Date();
      if (
        data.availableDays &&
        data.availableDays.length &&
        !data.availableDays.some((day) => day.value === today.getDay().toString())
      ) {
        message = 'Disponible solo';
        if (data.availableDays.length > 1) {
          message += ' los dias:';
          data.availableDays.forEach(
            (day, idx) => (message += (idx === data.availableDays.length - 1 ? ' y ' : ' ') + day.label),
          );
        } else {
          message += ' el dia ' + data.availableDays[0].label;
        }
      }
      const eventsManager = EventsManager.giveEventsManager();
      if (eventsManager) eventsManager.publish('displayNotification', { type: 'error', message: message });
      return;
    }
    if (invalidCart) {
      if (errorState) {
        let errorBoxes = scroller.current.querySelectorAll('[box_error="true"]');
        scroller.current.scroll({
          top: errorBoxes[0].offsetTop - 20,
          behavior: 'smooth',
        });
        for (let ii = 0; ii < errorBoxes.length; ii++) {
          const element = errorBoxes[ii];
          element.classList.add('animateBoxError');
        }
      }
      setErrorState(true);
      return;
    }
    let cartController = CartController.giveCartController();
    if (cartController)
      cartController.addProduct({
        id: data.id,
        categoryId: data.categoryId,
        name: data.name,
        image: data.image,
        description: data.description,
        isDisabled: data.isDisabled,
        itemId: data.itemId,
        options: cartOptions,
        sections: cartSections,
        suggestions: cartSuggestions,
        count: itemCount,
        directions: directions,
      });
    if (fbq) fbq('track', 'AddToCart', { content_name: data.name });

    let eventsManager = EventsManager.giveEventsManager();
    if (eventsManager) eventsManager.publish('onPageExitRequest', { id: 'Product_Page' });
  }, [cartOptions, cartSections, cartSuggestions, itemCount, isDisabled, invalidCart, directions, errorState]);

  const handleOptionChange = useCallback((lines) => {
    setCartOption(lines);
  }, []);

  useEffect(() => {
    let tokenUpdate;
    let eventsManager = EventsManager.giveEventsManager();
    if (eventsManager) {
      tokenUpdate = eventsManager.subscribe('onProductAvailabilityChanged', (productId) => {
        if (productId === data.id) setItemCount(data.isDisabled ? 0 : 1);
      });
    }
    return () => {
      if (eventsManager && tokenUpdate) eventsManager.unsubscribe(tokenUpdate);
    };
  }, []);

  const handleSectionChange = useCallback(
    (lines, idx) => {
      let newSections = cartSections.slice();
      newSections[idx].content = lines;
      setCartSections(newSections);
    },
    [cartSections],
  );

  const handleSuggestionChange = useCallback(
    (lines, idx) => {
      let newSuggestion = cartSuggestions.slice();
      newSuggestion[idx].content = lines;
      setCartSuggestions(newSuggestion);
    },
    [cartSuggestions],
  );

  const handleCountClick = useCallback(
    ({ state }) => {
      let newCount = state ? itemCount + 1 : itemCount - 1;
      setItemCount(newCount);
    },
    [itemCount],
  );

  const handleImageError = useCallback(() => {
    setImageError(true);
  }, []);

  const handleShare = useCallback(() => {
    const eventsManager = EventsManager.giveEventsManager();
    if (eventsManager) eventsManager.publish('onShare', 'producto?id=' + data.id);
  }, []);

  const totalSections = cartSections.reduce(
    (acc, item) => acc + item.content.reduce((acc2, cont) => (cont.price ? acc2 + cont.price * cont.count : acc2), 0),
    0,
  );
  const totalSuggestions = cartSuggestions.reduce(
    (acc, item) => acc + item.content.reduce((acc2, cont) => (cont.price ? acc2 + cont.price * cont.count : acc2), 0),
    0,
  );
  const total =
    itemCount * (cartOptions.reduce((acc, item) => acc + item.price * item.count, 0) + totalSections || 0) +
    totalSuggestions;

  return (
    <Page id="Product_Page">
      <div ref={scroller} className="product_page">
        <FlatButton
          icon="fa-solid:share-square"
          className="product_page-share_button"
          onClick={handleShare}
        ></FlatButton>
        {image && !imageError ? (
          <div className={`image-container ${isOpenImg ? 'open' : ''}`}>
            <motion.div
              animate={{ opacity: isOpenImg ? 1 : 0 }}
              transition={transition}
              className="shade"
              onClick={() => setOpenImg(false)}
            />
            <motion.img
              alt="producto"
              src={image}
              onError={handleImageError}
              LayoutTransition={transition}
              onClick={() => setOpenImg(!isOpenImg)}
            />
          </div>
        ) : (
          <div className="product_page-image default">
            <Icon icon="uil:camera" />
            <span>Producto Sin Imagen</span>
          </div>
        )}
        <p className="product_page-title">{name}</p>
        <p className="product_page-description">{description}</p>
        <ListBox
          title="Opciones"
          content={cartOptions}
          disabled={isDisabled}
          type="radio"
          maxValues={1}
          onLineChange={handleOptionChange}
        />
        {cartSections &&
          cartSections.map((section, idx) => (
            <ListBox
              key={idx}
              type={
                section.type === 'cont' ? 'count' : section.maxValues === 1 && section.isRequired ? 'radio' : 'checkbox'
              }
              disabled={isDisabled || section.isDisabled}
              title={section.title}
              content={section.content}
              isOnError={section.isRequired && !section.isDisabled && errorState}
              maxValues={section.maxValues}
              onLineChange={(lines) => {
                handleSectionChange(lines, idx);
              }}
            />
          ))}
        {Boolean(cartSuggestions && cartSuggestions.length) && (
          <span className="product_page-suggestion-title">Nuestras Sugerencias:</span>
        )}
        {cartSuggestions &&
          cartSuggestions.map((suggestion, idx) => (
            <ListBox
              key={idx}
              type={suggestion.type === 'cont' ? 'count' : 'checkbox'}
              disabled={isDisabled || suggestion.isDisabled}
              title={suggestion.title}
              content={suggestion.content}
              maxValues={suggestion.maxValues}
              onLineChange={(lines) => {
                handleSuggestionChange(lines, idx);
              }}
            />
          ))}
        <Card>
          <LineCheckBox
            type="count"
            text={isDisabled ? 'Agotado' : 'Cantidad'}
            disabled={isDisabled}
            minValue={1}
            value={itemCount}
            onChange={handleCountClick}
          />
        </Card>
        <Card>
          <p className="card-title">Instrucciones especiales</p>
          <br />
          <Comments
            placeHolder="Ejemplo: No agregar salsa"
            value={directions}
            onValueChange={(value) => {
              setDirections(value);
            }}
          />
        </Card>
      </div>
      {data?.planId && data.planId !== 'FREEMIUM' ? (
        <BarButton onClick={handleCartButton} disabled={isDisabled || isLoading}>
          <div className="product_page-bar_button">
            <Icon icon="ic:round-add-shopping-cart" />
            <span>{`${isUpdate ? 'Actualizar' : 'Añadir'} ${itemCount} por ${formatCurrency(total)}`}</span>
          </div>
        </BarButton>
      ) : null}
    </Page>
  );
}

export default memo(ProductPage);

ProductPage.propTypes = {
  data: PropTypes.exact({
    id: PropTypes.string.isRequired,
    extras: PropTypes.arrayOf({
      id: PropTypes.string.isRequired,
      nombre: PropTypes.string.isRequired,
      precio: PropTypes.number.isRequired,
      estatus: PropTypes.string.isRequired,
    }),
    name: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    isDisabled: PropTypes.bool.isRequired,
    categoryId: PropTypes.string,
    planId: PropTypes.string.isRequired,
    itemId: PropTypes.string,
    count: PropTypes.number,
    directions: PropTypes.string,
    availableDays: PropTypes.arrayOf(
      PropTypes.exact({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }),
    ),
    options: PropTypes.arrayOf(
      PropTypes.exact({
        label: PropTypes.string.isRequired,
        isDisabled: PropTypes.bool.isRequired,
        price: PropTypes.number.isRequired,
        count: PropTypes.number.isRequired,
      }),
    ).isRequired,
    sections: PropTypes.arrayOf(
      PropTypes.exact({
        title: PropTypes.string.isRequired,
        isRequired: PropTypes.bool,
        type: PropTypes.string.isRequired,
        isDisabled: PropTypes.bool.isRequired,
        maxValues: PropTypes.number.isRequired,
        content: PropTypes.arrayOf(
          PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.exact({
              label: PropTypes.string.isRequired,
              price: PropTypes.number.isRequired,
              isDisabled: PropTypes.bool.isRequired,
              count: PropTypes.number.isRequired,
            }),
          ]),
        ).isRequired,
      }),
    ),
    suggestions: PropTypes.arrayOf(
      PropTypes.exact({
        title: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        isDisabled: PropTypes.bool.isRequired,
        maxValues: PropTypes.number.isRequired,
        content: PropTypes.arrayOf(
          PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.exact({
              label: PropTypes.string.isRequired,
              price: PropTypes.number.isRequired,
              isDisabled: PropTypes.bool.isRequired,
              count: PropTypes.number.isRequired,
            }),
          ]),
        ).isRequired,
      }),
    ),
  }),
};
