/* global google */
import React, { useCallback, useState, useEffect, useRef } from 'react';
import { GoogleMap, Autocomplete, MarkerF } from '@react-google-maps/api';
import PropTypes from 'prop-types';
import Page from 'Elements/Page';
import EventsManager from 'Controllers/EventsManager';
import { Icon } from '@iconify/react';
import BarButton from 'Elements/BarButton';
import Marker from 'Images/marker.png';
import CommerceMarker from 'Images/commerce_marker.png';
import 'Styles/MapPage.scss';
import { useGoogleMapsContext } from '../context/GoogleMapsContext';
import { DELIVERY_TYPES_MAP, MODULES_MAP } from '../utils/constants';

const defaultLatLng = {
  lat: 17.983299,
  lng: -92.948272,
};

function MapPage({ data }) {
  const isLoaded = useGoogleMapsContext();
  const commerceLocation = data.commerceLocation
    ? {
        lat: data.commerceLocation.latitud,
        lng: data.commerceLocation.longitud,
      }
    : null;
  const [location, setLocation] = useState(data.location || commerceLocation || defaultLatLng);
  const [isLocating, setIsLocating] = useState(false);
  const inputPlaceRef = useRef(null);

  const getAddressComponents = (components) => {
    const addressComponents = {
      outNumber: '',
      street: '',
      zone: '',
    };
    components.forEach((comp) => {
      if (comp.types.includes('street_number')) {
        addressComponents.outNumber = comp.long_name;
      } else if (comp.types.includes('route')) {
        addressComponents.street = comp.long_name;
      } else if (comp.types.includes('sublocality')) {
        addressComponents.zone = comp.long_name;
      }
    });

    return addressComponents;
  };

  const handleInputChanged = () => {
    const inputAddress = inputPlaceRef.current?.value;
    if (!inputAddress) {
      return;
    }

    setIsLocating(true);

    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address: inputAddress }).then((res) => {
      if (res.results?.[0]) {
        setLocation({
          lat: res.results[0].geometry.location.lat(),
          lng: res.results[0].geometry.location.lng(),
        });
      } else {
        const eventsManager = EventsManager.giveEventsManager();
        if (!eventsManager) return;
        eventsManager.publish('displayNotification', {
          type: 'error',
          message: 'No se encontraron resultados para la dirección ingresada',
        });
      }

      setIsLocating(false);
    });
  };

  useEffect(() => {
    if (
      !location &&
      navigator?.geolocation?.getCurrentPosition &&
      (JSON.stringify(location) === JSON.stringify(defaultLatLng) ||
        JSON.stringify(location) === JSON.stringify(commerceLocation))
    ) {
      navigator.geolocation.getCurrentPosition((position) => {
        setLocation({ lat: position.coords.latitude, lng: position.coords.longitude });
      });
    }
  }, [location, defaultLatLng, commerceLocation]);

  const handleValidate = useCallback(async () => {
    const { lat, lng } = location;
    if (isLoaded && lat && lng) {
      setIsLocating(true);
      const geocoder = new google.maps.Geocoder();
      const res = await geocoder.geocode({ location: { lat, lng } });
      setIsLocating(false);

      if (!res.results?.[0]) {
        const eventsManager = EventsManager.giveEventsManager();
        if (!eventsManager) return;
        eventsManager.publish('displayNotification', {
          type: 'error',
          message: 'No se encontraron resultados, intenta de nuevo',
        });
      }

      const inputAddress = inputPlaceRef.current?.value;

      let eventsManager = EventsManager.giveEventsManager();
      if (eventsManager)
        eventsManager.publish('onPageExit', {
          location: { lat, lng },
          fullAddress: inputAddress || res.results[0].formatted_address,
          ...getAddressComponents(res.results[0].address_components),
          street: inputAddress
            ? inputAddress.split(',')[0]
            : getAddressComponents(res.results[0].address_components).street,
        });
    }
  });

  const isOriginPhone = localStorage.getItem('tipo-captura');
  const hasGeoModule = data.modules?.includes(MODULES_MAP.GEOLOCALIZACION_AVANZADA);
  const isButtonEnabled =
    location &&
    JSON.stringify(location) !== JSON.stringify(commerceLocation) &&
    JSON.stringify(location) !== JSON.stringify(defaultLatLng);

  return (
    <>
      <Page id="Map_Page">
        {isLoaded && (
          <div className="map-wrapper">
            <p className="map-title">Compartir ubicación</p>
            <p className="map-description">Mueve el marcador a tu ubicación</p>
            <GoogleMap
              options={{
                fullscreenControl: false,
                streetViewControl: false,
                mapTypeControl: false,
                zoomControl: false,
                gestureHandling: 'greedy',
              }}
              mapContainerStyle={{ width: '100%', height: 'calc(100vh - 130px)', borderRadius: '10px' }}
              center={location}
              zoom={
                JSON.stringify(location) === JSON.stringify(defaultLatLng) ||
                JSON.stringify(location) === JSON.stringify(commerceLocation)
                  ? 15
                  : 19
              }
            >
              {(isOriginPhone || hasGeoModule) && (
                <div className="map-autocomplete">
                  <Autocomplete onPlaceChanged={() => handleInputChanged()}>
                    <input placeholder="Escribe la dirección a buscar" ref={inputPlaceRef} />
                  </Autocomplete>
                </div>
              )}
              <MarkerF
                position={location}
                draggable
                zIndex={2}
                onDragEnd={({ latLng }) => {
                  if (latLng) {
                    setLocation({ lat: latLng.lat(), lng: latLng.lng() });
                  }
                }}
                icon={{
                  url: Marker,
                  scaledSize: new window.google.maps.Size(40, 65),
                }}
              />
              {commerceLocation && (
                <MarkerF
                  zIndex={1}
                  position={commerceLocation}
                  icon={{
                    url: CommerceMarker,
                    scaledSize: new window.google.maps.Size(40, 40),
                  }}
                />
              )}
            </GoogleMap>
            <BarButton
              onClick={() => (isLocating || !isButtonEnabled ? null : handleValidate())}
              disabled={!isButtonEnabled}
            >
              <div className="map-bar_button">
                <span>Fijar ubicación</span>
                <Icon icon={isLocating ? 'eos-icons:loading' : 'uil:angle-right'} />
              </div>
            </BarButton>
          </div>
        )}
      </Page>
    </>
  );
}

export default MapPage;

MapPage.propTypes = {
  data: PropTypes.exact({
    location: PropTypes.exact({
      lat: PropTypes.number,
      lng: PropTypes.number,
    }),
    commerceLocation: PropTypes.exact({
      latitud: PropTypes.number,
      longitud: PropTypes.number,
    }),
    deliveryType: PropTypes.oneOf(Object.values(DELIVERY_TYPES_MAP)).isRequired,
    fullAddress: PropTypes.string,
    modules: PropTypes.arrayOf(PropTypes.string),
  }),
};
