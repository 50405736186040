import React from 'react';
import PropTypes from 'prop-types';
import 'Styles/BranchOfficeItem.scss';
import { Icon } from '@iconify/react';
import CoverDefault from '../../assets/images/default-cover.jpg';
import StatusLabel from 'Elements/StatusLabel';

function BranchOfficeItem({ nombre, horarios, abierto, tags, tiempomin, tiempomax, onClick, direccion, cover }) {
  return (
    <div className="branch_office_item" onClick={onClick}>
      <div className="div_cover-item">
        {cover && <img src={cover ? cover : CoverDefault} alt={`Cover of ${nombre}`} className="cover_item" />}
      </div>
      <div className="branch_office_item_header">
        <Icon icon="mdi:store-marker" color="#253746" width="45" />
        <p className="branch_office_item_text">{nombre}</p>
      </div>
      <div className="branch_office_item_body">
        <StatusLabel isOpen={abierto} />
        <p className="branch_office_item_text branch_office_item_schedule">| Horario:</p>
        {horarios && horarios[0] && (
          <p className="branch_office_item_hour">{`${horarios[0].horario_apertura}h a ${horarios[0].horario_cierre}h`}</p>
        )}
        {!horarios?.[0] && <p className="branch_office_item_hour">No laboramos</p>}
      </div>
      {tiempomax && (
        <div className="branch_office_item_aprox">
          <span className="branch_office_item_aprox_text1">Tiempo de entrega:</span>
          <span className="branch_office_item_aprox_text2">
            {tiempomin ? `${tiempomin}-${tiempomax} min aprox.` : `${tiempomax} minutos aprox.`}
          </span>
        </div>
      )}

      {tags && (
        <div className="branch_office_item_tags">
          {tags.map((item, idx) => {
            return (
              <div className="branch_office_item_tags_name" key={'BranchOfficeItem-' + idx}>
                {item.nombre}
              </div>
            );
          })}
        </div>
      )}
      {direccion && (
        <div className="branch_office_item_address">
          <Icon icon="mdi:google-maps" width="38px" height="38px" color="#14202C" />

          {direccion}
        </div>
      )}
      <p className="branch_office_item_menu primary">Ver menú</p>
    </div>
  );
}

export default BranchOfficeItem;

BranchOfficeItem.propTypes = {
  nombre: PropTypes.string.isRequired,
  horarios: PropTypes.array.isRequired,
  tags: PropTypes.arrayOf(PropTypes.exact({ id: PropTypes.string, nombre: PropTypes.string })),
  abierto: PropTypes.bool.isRequired,
  direccion: PropTypes.string.isRequired,
  cover: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  tiempomin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  tiempomax: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
