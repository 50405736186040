import EventsManager from 'Controllers/EventsManager';

class PageControllerInstance {
  constructor({ baseUrl }) {
    this.baseUrl = baseUrl;
    this.stack = [];
    this.notifications = [];
    const startHistoryLength = window.history.length;
    let eventsManager = EventsManager.giveEventsManager();
    if (eventsManager) {
      this.onGoToHomeToken = eventsManager.subscribe('onGoToHome', () => {
        window.history.go(startHistoryLength - window.history.length);
      });
    }
    this.onPagePop = (data) => {
      while (this.stack.length > 1 && (!data.state || this.stack.at(-1).id !== data.state.id)) {
        this.stack.pop();
      }
      const lastState = this.stack.at(-1);
      window.history.replaceState({ id: lastState.id }, lastState.id, this.baseUrl + (lastState.url || ''));
      if (this.stack.length === 1) document.body.style.overflow = '';
      if (eventsManager) eventsManager.publish('onPagePop', this.stack.at(-1).id);
    };
    window.addEventListener('popstate', this.onPagePop);
    window.addEventListener('resize', this.onWindowsResize);
  }
  onWindowsResize() {
    let eventsManager = EventsManager.giveEventsManager();
    if (eventsManager) eventsManager.publish('onWindowsResize');
  }

  pushPage(data) {
    let url = this.baseUrl + (data.url || '');
    if (this.stack.length > 0 || url !== window.location.pathname) {
      document.body.style.overflow = 'hidden';
      window.history.pushState({ id: data.id }, data.id, this.baseUrl + (data.url || ''));
    }
    this.stack.push(data);
    let eventsManager = EventsManager.giveEventsManager();
    if (eventsManager) eventsManager.publish('onPagePush', data.id);
  }
  displayNotification(data) {
    if (this.notifications.some((notification) => notification.id === data.id)) return;
    this.notifications.push(data);
    let eventsManager = EventsManager.giveEventsManager();
    if (eventsManager) {
      eventsManager.publish('onPagePush', data.id);
      let token = eventsManager.subscribe('onNotificationExit', () => {
        eventsManager.unsubscribe(token);
        let index = this.notifications.indexOf(data);
        if (index >= 0) {
          this.notifications.splice(index, 1);
          if (eventsManager) eventsManager.publish('onPagePop');
        }
      });
    }
  }
  updatePageState(data) {
    let pageData = this.stack.find((page) => page.id === data.id);
    if (pageData) {
      pageData.state = data.state;
      let eventsManager = EventsManager.giveEventsManager();
      if (eventsManager) eventsManager.publish('onPageStateUpdate', data.id);
    }
  }
  popPage() {
    if (this.stack.length > 1) this.onPagePop({ state: { id: this.stack.at(-2).id } });
  }
  getPages() {
    return [...this.stack, ...this.notifications];
  }

  clear() {
    window.removeEventListener('popstate', this.onPagePop);
    window.removeEventListener('resize', this.onWindowsResize);
    let eventsManager = EventsManager.giveEventsManager();
    if (eventsManager) this.onGoToHomeToken = eventsManager.unsubscribe(this.onGoToHomeToken);
    this.onGoToHomeToken = null;
    this.stack = null;
  }
}

let instance;
export default class PageController {
  static getPageController(params) {
    if (!instance) {
      let controller = new PageControllerInstance(params);
      instance = Object.freeze({
        pushPage: (data) => {
          if (controller) controller.pushPage(data);
        },
        displayNotification: (data) => {
          if (controller) controller.displayNotification(data);
        },
        updatePageState: (data) => {
          if (controller) controller.updatePageState(data);
        },
        popPage: () => {
          if (controller) controller.popPage();
        },
        getPages: () => {
          if (controller) return controller.getPages();
        },
        subscribe: (event, callback) => {
          if (controller) return controller.subscribe(event, callback);
        },
        unsubscribe: (token) => {
          if (controller) return controller.unsubscribe(token);
        },
        clear: () => {
          if (controller) controller.clear();
          controller = null;
        },
      });
    }
    return instance;
  }
  static givePageController() {
    return instance;
  }
  static unreferencePageController() {
    if (instance) instance.clear();
    instance = null;
  }
}
